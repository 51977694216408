import { IconLookup } from '@fortawesome/fontawesome-common-types'
import { IconParams, icon as getIconDefinition } from '@fortawesome/fontawesome-svg-core'
import { SvgIcon, SvgIconProps } from '@material-ui/core'
import React, { PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'

export type BaseIconProps<P extends Record<string, unknown>> = SvgIconProps & P & { params?: IconParams }

export type OwnProps = {
  icon: IconLookup
  inline?: boolean
}

export type StyledProps = {
  $inline?: boolean
}

export type IconProps = BaseIconProps<OwnProps>

export const StyledSvgIcon = styled(SvgIcon)<StyledProps>`
  ${props => props.$inline && css`
    position: relative;
    top: ${props => props.theme.typography.pxToRem(2)};
    width: 1em;
    height: 1em;
    font-size: inherit;
    margin-right: ${props => props.theme.typography.pxToRem(10)};
  `}
`

export const Icon: React.FC<PropsWithChildren<IconProps>> = ({ icon: iconLookup, params, inline, ...props }) => {
  const def = getIconDefinition(iconLookup, params)

  if (!def) {
    return null
  }

  const [width, height, _ligature, _unicode, path] = def.icon

  return (
    <StyledSvgIcon $inline={inline} { ...props } viewBox={`0 0 ${width} ${height}`}>
      {Array.isArray(path) ? path.map((p, i) => <path key={i} d={p} />) : <path d={path} />}
    </StyledSvgIcon>
  )
}